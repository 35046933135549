// import './bootstrap';

import $ from 'jquery';
window.$ = $;

// ==================

var n = navigator.userAgent,
	i = location.hash;
window.addEventListener("load", (function() {
	var e = new IntersectionObserver((function(e) {
			e.forEach((function(e) {
				e.isIntersecting && e.target.classList.add("is-animated")
			}))
		}), {
			rootMargin: "0% 0% -100px 0%",
			threshold: 0
		}),
		t = document.querySelectorAll(".js-c-anime-elem");
	Array.prototype.slice.call(t).forEach((function(t) {
		return e.observe(t)
	}))
}), !1);
var o = $(window),
	r = $("body,html");
if ($((function() {
		var e = o.innerWidth(),
			t = $("body").css("min-width");
		if (0 !== (t = Number(t.replace("px", "")))) {
			var i = e / t;
			!(n.indexOf("iPhone") > -1 || n.indexOf("Android") > -1 && n.indexOf("Mobile") > -1) && (n.indexOf("iPad") > -1 || n.indexOf("Macintosh") > -1 && "ontouchend" in document || n.indexOf("Android") > -1) && $('meta[name="viewport"]').attr("content", "width=" + e + ", initial-scale=" + i)
		}
	})), $((function() {
		var e;
		o.on("resize load", (function() {
			function t() {
				var e = function() {
					var e = $(window);
					return {
						vh: .01 * e.innerHeight(),
						vw: .01 * e.innerWidth()
					}
				}();
				document.documentElement.style.setProperty("--vh", e.vh + "px"), document.documentElement.style.setProperty("--vw", e.vw + "px")
			}
			clearTimeout(e), e = setTimeout((function() {
				t()
			}), 100)
		}))
	})), $((function() {
		o.on("load", (function() {
			if (i) {
				var e = $(i);
				if (e.length) {
					var t = $(".js-header").innerHeight(),
						n = e.offset().top;
					t && (n -= t), r.animate({
						scrollTop: n
					}, 300, "swing")
				}
			}
		})), $('a[href^="#"]').on("click", (function() {
			var e = $(this).attr("href"),
				t = $("#" === e || "" === e ? "html" : e),
				n = 0;
			if ("#top" !== e) {
				var i = $(".js-header").innerHeight();
				n = t.offset().top, i && (n -= i)
			}
			return r.animate({
				scrollTop: n
			}, 300, "swing"), !1
		}))
	})), window.addEventListener("load", (function() {
		var e = new IntersectionObserver((function(e) {
				e.forEach((function(e) {
					if (e.isIntersecting) {
						var t = e.target,
							n = t.dataset.src,
							i = t.dataset.srcset;
						n && (t.src = n, t.removeAttribute("data-src")), i && (t.srcset = i, t.removeAttribute("data-srcset"))
					}
				}))
			}), {
				rootMargin: "10% 10%",
				threshold: 0
			}),
			t = document.querySelectorAll("[data-src],[data-srcset]");
		Array.prototype.slice.call(t).forEach((function(t) {
			return e.observe(t)
		}))
	}), !1), $((function() {
		var e = this,
			t = $(".js-page-top");
		o.on("load scroll", (function() {
			if ("sp" !== $(".js-media-query").css("font-family").replace(/"/g, "")) {
				$(e).scrollTop() > 300 ? t.addClass("is-shown") : t.removeClass("is-shown")
			}
		}))
	})), $((function() {
		var e = $(".js-nav-btn"),
			t = $(".js-nav-content"),
			n = $(".js-nav-btn-txt"),
			i = $(".js-nav-overlay");

		function o() {
			i.fadeOut(300), t.removeClass("is-open"), e.removeClass("is-open"), n.text("menu")
		}
		e.on("click", (function() {
			return t.hasClass("is-open") ? o() : (i.fadeIn(300), t.addClass("is-open"), e.addClass("is-open"), n.text("close")), !1
		})), i.on("click", (function() {
			o()
		})), t.on("click", (function(e) {
			e.stopPropagation()
		}));
		var r = $(".js-nav-toggle-area");
		document.addEventListener("click", (function(e) {
			e.target.closest(".js-nav-toggle-area") || (r.find(".js-c-toggle-trigger").removeClass("is-active"), r.find(".js-c-toggle-content").slideUp(300))
		}))
	})), $(".js-header").length > 0) {
	// remove
	// var s = document.querySelector(".js-header");
	// window.addEventListener("scroll", (function() {
	// 	window.pageYOffset > 350 ? s.classList.add("is-fixed") : s.classList.remove("is-fixed")
	// }))
}
if ($(".js-footer-cv").length > 0) {
	var a = document.querySelector(".js-footer-cv"),
		l = document.querySelector(".js-footer"),
		c = document.querySelector(".js-footer-cv-close");
	window.addEventListener("scroll", (function() {
		var e = window.pageYOffset,
			t = l.getBoundingClientRect().top;
		e > 250 ? (a.classList.add("is-show"), t < 1000 ? a.classList.remove("is-show") : a.classList.add("is-show")) : a.classList.remove("is-show")
	})), c.addEventListener("click", (function() {
		a.classList.add("is-hide")
	}))
}
if ($(".js-sp-cv").length > 0) {
	var d = document.querySelector(".js-sp-cv");
	window.addEventListener("scroll", (function() {
		window.pageYOffset > 0 ? d.classList.add("is-show") : d.classList.remove("is-show")
	}))
}
if ($((function() {
		var e = $(".js-c-switch-tab-list"),
			t = $(".js-c-switch-tab"),
			n = $(".js-c-switch-content-list"),
			i = $(".js-c-switch-content");
		t.on("click", (function() {
			var o = $(this),
				r = o.parent(e),
				s = r.children(t),
				a = r.next(n).children(i),
				l = s.index(this);
			a.removeClass("is-active"), s.removeClass("is-active"), a.eq(l).addClass("is-active"), o.addClass("is-active")
		}))
	})), $((function() {
		var e = function(e, t) {
			if (e) {
				e = e.replace(/[\[\]]/g, "\\$&"), t || (t = window.location.href);
				var n = new RegExp("[?&]" + e + "(=([^&#]*)|&|#|$)").exec(t);
				return n ? n[2] ? decodeURIComponent(n[2].replace(/\+/g, " ")) : "" : null
			}
		}("tab");
		if (e && $(".js-c-switch-tab").length > 0) {
			var t = e.split("_");
			if (t) {
				var n = t[0],
					i = t[1],
					o = $('.js-c-switch-tab-list[data-tab-name="' + n + '"]'),
					r = $('.js-c-switch-content-list[data-tab-name="' + n + '"]');
				o.children(".js-c-switch-tab").removeClass("is-active"), r.children(".js-c-switch-content").removeClass("is-active"), r.children('.js-c-switch-content[data-tab-num="' + i + '"]').addClass("is-active")
			}
		}
	})), $((function() {
		$(".js-c-toggle-trigger").on("click", (function() {
			var e = $(this);
			e.toggleClass("is-active"), e.next(".js-c-toggle-content").slideToggle(300)
		}))
	})), $(".js-mv-slider").length > 0 && document.addEventListener("DOMContentLoaded", (function() {
		var e, t = document.querySelectorAll(".js-mv-slider img"),
			n = t.length,
			i = 1;
		t[0].classList.add("is-current");
		var o = function() {
			i >= n && (i = 0), e = 0 == i ? n - 1 : i - 1, t[i].classList.add("is-current"), t[e].classList.add("is-prev"), setTimeout(function(e) {
				t[e].classList.remove("is-current"), t[e].classList.remove("is-prev")
			}.bind(null, e), 1500), i++
		};
		setTimeout((function() {
			setInterval(o, 7e3), o()
		}), 7e3)
	})), $(".js-flow-slider").length > 0) {
	var u = document.querySelector(".js-flow-slider");
	new Swiper(u, {
		slidesPerView: "auto",
		spaceBetween: 10,
		freeMode: !0,
		grabCursor: !0,
		scrollbar: {
			el: ".js-flow-slider-scrollbar",
			draggable: !0
		},
		breakpoints: {
			768: {
				spaceBetween: 20
			}
		}
	})
}
if ($(".js-about-slider").length > 0) {
	var p = document.querySelector(".js-about-slider");
	new Swiper(p, {
		effect: "fade",
		loop: !0,
		speed: 1e3,
		pagination: {
			el: ".js-about-slider-pagination",
			type: "bullets",
			clickable: !0
		},
		navigation: {
			nextEl: ".js-about-slider-next",
			prevEl: ".js-about-slider-prev"
		},
		autoplay: {
			delay: 4e3,
			disableOnInteraction: !1
		}
	})
}

document.querySelector(".js-footer-cv-close.l-footer-cv__close").addEventListener('click', function(e) {
	const elm = document.querySelector(".js-footer-cv.l-footer-cv.u-view-upper-tb");
	elm && elm.remove();
});